.form-error {
  display: block;
  margin-top: initial;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
}

.error-message {
  background: #cc4b37;
  color: $white;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
}

form {
  padding: 0 0.75em 0;

  select {
    width: initial !important;
    margin-right: 15px;
    min-width: 120px;
  }

  .file label {
    font-weight: bold;
    margin-bottom: 0.75rem;
  }

  .callout {
    margin-top: 1rem;
    margin-bottom: 1rem;

    * {
      font-family: Arial !important;
      font-weight: bold;
    }

  }

}

.warranty-form {

  h2 {
    margin-top: 2rem;

    @include breakpoint(small only) {
      font-size: 2rem;
    }

  }

  .full-width-select {

    input, select {
      min-width:100%;
    }

  }

  input:checked ~ .switch-paddle {
    background: #000000;
  }

}



