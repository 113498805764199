.dealers-page {

	padding: 4em 0em;


	@include breakpoint(medium down) {
		padding: 2em 0em;
	}

	.intro-container {

		@include breakpoint(small only) {
			padding: 0em 1em;
		}

		p {
			margin-bottom: 4em;

			@include breakpoint(medium down) {
				margin-bottom: 2.5em;
				margin-top: 1em;
			}
		}

		h1 {

			font-size: 95px;
			line-height: 95px;

			@include breakpoint(medium down) {
				font-size: 63px;
				line-height: 63px;
			}

			@include breakpoint(small only) {
				font-size: 41px;
				line-height: 41px;
			}
		}

	}


	.grid-margin-x > .cell {
		@include breakpoint(medium down) {
			width: 100%;
			margin-left: 0px;
			margin-right: 0px;
		}
	}



	.map-container {
		max-width: 100%;
		padding: 0px;



		#wpsl-wrap {
			background-color: #f5f5f5;
			padding-bottom: 3em;
		}

		#wpsl-stores {
			@include breakpoint(medium down) {
				padding: 0em 1.5em;
			}

			h3 {
				font-size: 2.2rem;
				line-height: 1.3;
				font-weight: normal;
				text-transform: uppercase !important;
				margin-bottom: 20px;
				display: block;
				border-bottom: 2px solid black;
				padding-bottom: 13px;
			}

		}

		.wpsl-search {
			margin: 0px;
		}

		#wpsl-search-wrap {
			max-width: 1340px;
			margin: auto;
			float: none;
		}

		#wpsl-result-list {
			width: 100%;
			max-width: 1350px;
			margin: auto;
			float: none;
			display: block;
			margin-top: 500px;

			@include breakpoint(medium down) {
				margin-top: 479px;
			}

			@include breakpoint(small only) {
				margin-top: 441px;
			}

			a {

				&:hover {
					text-decoration: underline;
				}

			}
		}

		#wpsl-direction-details {

			@include breakpoint(medium down) {
				padding: 0em 1.5em;
			}

		}

		#wpsl-gmap {
			width: 100%;
			float: left;
			height: 450px !important;

			@include breakpoint(small only) {
				height: 400px !important;
			}
		}

		#wpsl-wrap #wpsl-result-list ul li {
			list-style: none !important;
			width: 32.7%;
			padding: 2em;
			display: inline-block;
			border: 1px dotted #c5c5c5;
			background-color: white;

			@include breakpoint(medium down) {
				width: 49.5%;
			}

			@include breakpoint(small only) {
				width: 100%;
				padding: 1.5em;
			}


		}

		.wpsl-store-location {




			strong:first-child {
				//font-size: 40px;
				//line-height: 38px;
				//font-family: 'Tungsten Medium';
				//font-weight: normal;
				//text-transform: uppercase;
				//margin-bottom: 20px;
				//display: block;
				//border-bottom: 2px solid black;
				//padding-bottom: 13px;

			}

		}

		.wpsl-contact-details {

			margin-top: 10px;

			strong {
				//display: none;
				font-size: 1rem;
				line-height: 1rem;
				font-family: initial;
				font-weight: normal;
				text-transform: initial;
				margin-bottom: auto;
				display: initial;
				border-bottom: none;
				padding-bottom: initial;
			}
		}

		.wpsl-input label, #wpsl-radius label, #wpsl-category label {
			width: 80px;
		}

		#wpsl-category label, #wpsl-radius label, .wpsl-cat-results-filter #wpsl-search-wrap .wpsl-input, .wpsl-input label, .wpsl-no-filters #wpsl-search-wrap .wpsl-input, .wpsl-results-only #wpsl-search-wrap .wpsl-input {
			@include breakpoint(medium down) {
				width: 85%;
				margin-right: 0px;
			}
		}

		.wpsl-input {
			width: 88%;

			@include breakpoint(medium down) {
				width: 100%;
			}

		}

		.wpsl-select-wrap {
			width: 30%;
		}

		.wpsl-search-btn-wrap {
			width: 10%;

			@include breakpoint(medium down) {
				width: 13%;
				margin: 0px;
				margin-left: -2px;
			}

			@include breakpoint(small only) {
				width: 100%;
				margin-left: 0px;
				margin-top: 12px;

				input {
					padding: 12px !important;
				}
			}
		}

		#wpsl-search-wrap div label {
			float: left;
			margin-right: 10px;
			line-height: 32px;
			font-size: 31px;
			font-family: 'Tungsten Medium';
			font-weight: normal;
			text-transform: uppercase;
			margin-top: 5px;
			text-align: right;
			width: 100%;
			padding-right: 18px;

			@include breakpoint(small only) {
				text-align: left;
				font-size: 25px;
				line-height: 21px;
				margin-bottom: 12px;

			}
		}

		#wpsl-search-input {
			width: 84%;
			border: 1px solid black;
			height: 43px;
			border-radius: 0px;

			@include breakpoint(medium down) {
				width: 70%;
				display: inline-block;
			}

		}

		.wpsl-search {
			padding: 30px 20px 20px;

			@include breakpoint(small only) {
				padding: 20px;
			}
		}

		#wpsl-radius {
			padding-right: 1em;
		}

		#wpsl-search-btn {
			margin: auto;
			float: none;
			width: 100%;
			background: black;
			box-shadow: none;
			border-radius: 0px;
			color: white;
			border: 1px solid black;
			font-family: 'Tungsten Medium';
			font-weight: normal;
			font-size: 20px;
			letter-spacing: 2px;
			text-transform: uppercase !important;
			padding: 6.5px 10px;
		}

		.wpsl-back {
			margin: auto;
			float: none;
			background: black;
			box-shadow: none;
			border-radius: 0px;
			color: white;
			border: 1px solid black;
			font-family: 'Tungsten Medium';
			font-weight: normal;
			font-size: 20px;
			letter-spacing: 2px;
			text-transform: uppercase !important;
			display: inline-block;
			padding: 6px 30px;
			margin-bottom: 20px;
		}

		#wpsl-wrap #wpsl-result-list #wpsl-direction-details ul li {
			width: 77%;

			@include breakpoint(small only) {
				width: 100%;
			}
		}

		.wpsl-direction-index, .wpsl-direction-txt, .wpsl-direction-distance {
			@include breakpoint(small only) {
				width: 100%;
				margin: 0px;
				margin-bottom: 10px;
			}
		}

		.wpsl-direction-wrap {
			border: 2px solid black;
			display: inline-block;
			padding: 12px 20px;
			text-transform: uppercase;
			font-family: 'Tungsten Medium';
			font-size: 19px;
			letter-spacing: 2px;
			margin-top: 10px;
		}


		#wpsl-wrap #wpsl-result-list ul {

			li {
				min-height: 300px;
			}


			li:hover {

				.wpsl-direction-wrap {
					background-color: black;
					cursor: pointer;

					-webkit-transition: .25s ease-in-out;
					-moz-transition: .25s ease-in-out;
					-o-transition: .25s ease-in-out;
					transition: .25s ease-in-out;

					a {
						cursor: pointer;
						color: white !important;
						-webkit-transition: .25s ease-in-out;
						-moz-transition: .25s ease-in-out;
						-o-transition: .25s ease-in-out;
						transition: .25s ease-in-out;
					}
				}

				.wpsl-store-location {

					p strong {
						font-weight: bold;
					}
				}


			}

		}


	}

	#wpsl-result-list li p {
		padding-left: 0;
		margin: 10px 0 10px;
	}

}