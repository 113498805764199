.button {
	padding: 16px 30px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: white;
    color: black;
    border: 2px solid black;
    margin: 1em 0em;
    font-family: 'Tungsten Medium';
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 2px;

     @include breakpoint(small only) {
        font-size: 17px;
        padding: 15px 30px;
        margin: 0.5em 0em;
     }
}

.button:hover, .wp-block-button .wp-block-button__link:hover, .button:focus, .wp-block-button .wp-block-button__link:focus {
	background-color: $black;
}