.device {
	width: 100%;
	

	span {
		    display: block;
	}

	.one {
		    border-bottom: 1px solid white;
    margin-bottom: 19px;
	}

	.two {
		border-bottom: 3px solid white;
    margin-bottom: 14px;
	}

	.three {
		border-bottom: 4px solid white;
    margin-bottom: 10px;
	}

	.four {
		    border-bottom: 5px solid white;
    margin-bottom: 4px;
	}

	.five {
		border-bottom: 8px solid white;
    margin-bottom: 3px;
	}

	.six {
		border-bottom: 10px solid white;
    margin-bottom: 0px;
	}

	.seven {
		border-bottom: 11px solid white;
    margin-bottom: 0px;
	}

	.eight {
		border-bottom: 18px solid white;
    margin-bottom: 2px;
	}

	.nine {
		border-bottom: 21px solid white;
    margin-bottom: 3px;
	}
}

.device-vertical {
	    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999999999999;

     @include breakpoint(small only) {
	 	display: none !important;
	 }

	

	span {
		display: inline-block;
	    height: 100%;
	    float: left;
	}

	.one {
		    border-right: 1px solid white;
    margin-right: 19px;
	}

	.two {
		border-right: 3px solid white;
    margin-right: 14px;
	}

	.three {
		border-right: 4px solid white;
    margin-right: 10px;
	}

	.four {
		    border-right: 5px solid white;
    margin-right: 4px;
	}

	.five {
		border-right: 8px solid white;
    margin-right: 3px;
	}

	.six {
		border-bottom: 10px solid white;
    margin-bottom: 2px;
	}

	.seven {
		border-right: 11px solid white;
    margin-right: 2px;
	}

	.eight {
		border-right: 18px solid white;
    margin-right: 2px;
	}

	.nine {
		border-right: 21px solid white;
    margin-right: 3px;
	}
}

.device-1 {
	position: absolute;
    transform: rotate(45deg);
    z-index: 9999;
    height: 150%;
    right: 14em;

    /*@include breakpoint(landscape) {
	 	right: 0em;
	 }*/

	 @include breakpoint(medium down) {
	 	right: -5em;
	 }
}

.device-video {
	position: absolute;
	bottom: 0em;
	 z-index: 9999;

	  @include breakpoint(medium down) {
	  	display: none;
	  }
}

.device-black {
	.one, .two, .three, .four, .five, .six, .seven, .eight, .nine {
		border-color: black;
	}
}

.lines-device {

	position: absolute;
	    bottom: -7em;
	right: 0;

	 @include breakpoint(medium down) {
	 	bottom: -16em;
	 }

	 @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	   		bottom: -8em;
	    }

	 @include breakpoint(small only) {
	 	bottom: -28em;
	 }


	span {
		width: 15px;
		background-color: white;
	    display: inline-block;
        margin-right: 9px;

        @include breakpoint(medium down) {
        	width: 11px;
        	margin-right: 6px;
        }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	   		width:10px;
	   		margin-right:3px;
	    }

         @include breakpoint(small only) {
         	 width: 10px;
         	 margin-right: 1px;
         }

		           
    

	}

	
	.slide-top {
	-webkit-animation: slide-top 1s ease-in-out infinite alternate ;
	        animation: slide-top 1s ease-in-out infinite alternate ;
		}

			@-webkit-keyframes slide-top {
		  0% {
		    -webkit-transform: translateY(0);
		            transform: translateY(0);
		  }
		  100% {
		    -webkit-transform: translateY(-100px);
		            transform: translateY(-100px);
		  }
		}
		@keyframes slide-top {
		  0% {
		    -webkit-transform: translateY(0);
		            transform: translateY(0);
		  }
		  100% {
		    -webkit-transform: translateY(-100px);
		            transform: translateY(-100px);
		  }
		}


	.one {
		height: 35vh;
	}

	.two {
		height: 35vh;
		animation-delay: .5s;
	}

	.three {
		height: 35vh;
		animation-delay: .6s;
	}
	.four {
		height: 30vh;
		animation-delay: .7s;
	}
	.five {
		height: 30vh;
		animation-delay: .8s;
	}
	.six {
		height: 30vh;
		animation-delay: .9s;
	}
	.seven {
		height: 40vh;
		animation-delay: 1s;
	}
	.eight {
		height: 40vh;
		animation-delay: 1.1s;
	}
	.nine {
		height: 40vh;
		animation-delay: 1.2s;
	}
	.ten {
		height: 35vh;
		animation-delay: 1.3s;
	}
	.eleven {
		height: 35vh;
		animation-delay: 1.4s;
	}
	.twelve {
		height: 35vh;
		animation-delay: 1.5s;
	}
	.thriteen {
		height: 30vh;
		animation-delay: 1.6s;
	}
	.fourteen {
		height: 30vh;
		animation-delay: 1.7s;
	}
	.fifteen {
		height: 30vh;
		animation-delay: 1.8s;
	}
	.sixteen {
		height: 45vh;
		animation-delay: 1.9s;
	}
	.seventeen {
		height: 45vh;
		animation-delay: 2s;
	}
	.eighteen {
		height: 45vh;
		animation-delay: 2.1s;
	}
	.nineteen {
		height: 35vh;
		animation-delay: 2.2s;
	}
	.twenty {
		height: 35vh;
		animation-delay: 2.3s;
	}
	.twenty-one {
		height: 35vh;
		animation-delay: 2.4s;
	}
	.twenty-two  {
		height: 40vh;
		animation-delay: 2.5s;
	}
	.twenty-three {
		height: 40vh;
		animation-delay: 2.6s;
	}
	.twenty-four {
		height: 40vh;
		animation-delay: 2.7s;
	}
	.twenty-five {
		height: 35vh;
		animation-delay: 2.8s;
	}
	.twenty-six {
		height: 35vh;
		animation-delay: 2.9s;
	}
	.twenty-seven {
		height: 35vh;
		animation-delay: 3s;
	}
	.twenty-eight {
		height: 40vh;
		animation-delay: 3.1s;
	}
	.twenty-nine {
		height: 40vh;
		animation-delay: 3.2s;
	}
	.thirty {
		height: 40vh;
		animation-delay: 3.3s;
	}
	.thirty-one {
		height: 30vh;
		animation-delay: 3.4s;
	}
	.thirty-two {
		height: 30vh;
		animation-delay: 3.5s;
	}
	.thirty-three {
		height: 30vh;
		animation-delay: 3.5s;
	}
	
}

.device-story-1 {
	    height: 78%;
    position: absolute;
    left: 0;
    bottom: 1em;


	span {
		border-right: 3px solid black;
    width: 2px;
    display: inline-block;
    float: left;
    height: 87%;
    margin-right: 14px;

    &:last-child {
    	margin-left:13px;
		}

		&:nth-last-child(2) {
			margin-left:13px;
		}
    }
}


.device-story-2 {
	    height: 78%;
    position: absolute;
    right: 0;
    bottom: 1em;

    @include breakpoint(medium down) {
	  	height: 41%;
	  	bottom: -1em;
	  }




	span {
		border-right: 3px solid black;
    width: 2px;
    display: inline-block;
    float: left;
    height: 91%;
    margin-right: 14px;

    &:first-child {
    	margin-right:53px;
		}

		&:nth-child(2) {
			margin-right:63px;
		}
		&:nth-child(3) {
			margin-right:23px;
		}
		&:nth-child(4) {
			margin-right:43px;
		}
		&:nth-child(5) {
			margin-right:23px;
		}
    }
}