.featured-hero {
	 
	height: 100vh;
	min-height: 800px;
	position: relative;
	overflow: hidden;
	background-color: black;


	 @include breakpoint(medium down) {
	 	height: 90vh;
	 	min-height: 0px;
	 }

 	@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
		height: 97vh;
	}


	 .content-container {
	 	position: relative;
	    height: 100%;
	    width: 100%;
	    max-width: 1440px;
	    margin: auto;
	    z-index: 99;
	   

	 }

	 .play-button {
	 	opacity: 0;
	 	width: 100px;
	    position: absolute;
	    top: 45vh;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    margin: auto;
	    -webkit-transition: all 1s; /* Safari */
  		transition: all 1s;

  		img {

  			width: 100px;
  			height: 100px;
  			
  			@include breakpoint(medium down) {
	  			width: 80px;
	  			height: 80px;
	  		}

  			@include breakpoint(small only) {
  				width: 60px;
  				height: 60px;
  			}
  		}

  		@include breakpoint(medium down) {
  			opacity: 1 !important;
  			width: 80px;
  		}

		@include breakpoint(small only) {
			width: 63px;
			top: 40vh;
		}
	 }

	 .show-play-button {
	 	opacity: 1 !important;

	 	-webkit-transition: all 1s; /* Safari */
  		transition: all 1s;
	 }

	 .scroll-down {
	 	position: absolute;
	    bottom: 60px;
	    text-align: center;
	    left: 0;
	    right: 0;
	    color: white;
	    text-transform: uppercase;

	    font-family: 'Tungsten Medium';
	    font-weight: normal;
	    font-size: 25px;
	    letter-spacing: 2px;

	    @include breakpoint(medium down) {
	    	bottom: 32px;
	    }

	    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
		   display: block !important;
		    }


	    @include breakpoint(small only) {
	    	font-size: 18px;
	    	
	    }

	    span {
	    	border-left: 2px solid white;
		    content: '';
		    position: relative;
		    display: block;
		    width: 0px;
		    margin: 15px auto 0px;
		   	height: 50px;
		  	animation: MoveUpDown 1.3s linear infinite;
		  	position: absolute;
		  	left: 0;
		  	bottom: 0px;
		  	right: 0;

		  	@keyframes MoveUpDown {
				  0%, 100% {
				    bottom: -70px;
				  }
				  50% {
				    bottom: -60px;
				  }
				}


		  	 @include breakpoint(medium down) {
		  	 	height: 35px;

				@keyframes MoveUpDown {
				  0%, 100% {
				    bottom: -50px;
				  }
				  50% {
				    bottom: -40px;
				  }
				}

			}
	    }
	 }

	 .stamp-icon {
	 	position: absolute;
	    height: 130px;
	    bottom: 2em;
	    left: 2em;

	   @include breakpoint(medium down) {
	    height: 100px;
		}

		 @include breakpoint(small only) {
		    height: 93px;
		    left: 0;
		    right: 0;
		    margin: auto;
		    bottom: 5em;
	    }
	 }




	 .vimeo-wrapper {
	   position: absolute;
	   top: 0;
	   left: 0;
	   width: 100%;
	   height: 100%;
	   z-index: -1;
	   pointer-events: none;
	   overflow: hidden;
	   z-index: 9;
	   background-color: black;

	    @include breakpoint(medium down) {
	    	background-color: rgba(0, 0, 0, 0);
	    }
	}
	.vimeo-wrapper iframe {
	   width: 100vw;
	   height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
	   min-height: 100vh;
	   min-width: 190.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
	   position: absolute;
	   top: 50%;
	   left: 50%;
	   transform: translate(-50%, -50%);
	}

	.change-position {
		z-index: 999999;
	}

	.video-overlay {
		position: absolute;
		top:0;
		left:0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.36);
    z-index: 99;
	}

	.mobile-image {
		position: absolute;
		top:0;
		left:0;
		right: 0;
		bottom: 0;
		background-size: cover;
		background-position: center;

		@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
			background-position-y: 37%; 
		}


		@include breakpoint(medium down) {
			animation: scale 20s linear infinite;
		}
	}


	.zoom {
	  
	}
	  
	@keyframes scale {
	  50% {
	    -webkit-transform:scale(1.1);
	    -moz-transform:scale(1.1);
	    -ms-transform:scale(1.1);
	    -o-transform:scale(1.1);
	    transform:scale(1.1);
	  }
	}

	.vimeo-mobile {
		opacity: 0;
		z-index: -1;
		background-color: black !important;

		iframe {
			width: 145%;
		    height: 100%;
		    min-height: 100%;
		    min-width: 100%;
		    top: 0;
		    left: 0;
		    -webkit-transform: none;
		    -ms-transform: none;
		    transform: none;
		}
	}

	.vimeo-mobile-show {
		opacity: 1;
		z-index: 999;
	}


}

.close-button {
		position: absolute;
	    top: 1em;
	    right: 1em;
	    display: none;
	    background-color: white;
	    padding: 14px 20px;
	    color: black;
	    z-index: 99999999;

	    @include breakpoint(medium down) {
	    	right: 0em;
		    top: 24px;
		    font-size: 21px;
		     padding: 9px 16px 11px;
	    }

	    img {
	    	width: 21px;
	    }
	}


.hero-guitars {

	h1 {
		position: absolute;
		color: white;
		text-align: center;
		bottom: 10vh;
		left: 0;
		right: 0;

		@include breakpoint(medium down) {
		 	 bottom: 7vh;
		 }

		 @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
			bottom: 9vh;
		}


		 @include breakpoint(small only) {
		 	bottom: 10vh;
		 }
	}

	.stamp-icon {
		@include breakpoint(small only) {
		 	 bottom: 10.5em;
		 }

	}
}

.hero-single-guitar {

	    overflow: visible;

	    @include breakpoint(medium down) {
	    	overflow: hidden;
	    }

	.guitar-image {
		
	   display: inline-block;
	   opacity: 0 !important;
	    float: left;
	    margin-top: 14vh;
    	    height: 91vh;
    	    -webkit-transition: all 0.5s; /* Safari */
  		transition: all 0.5s;

  		@include breakpoint(medium down) {
  			height: 79vh;
  			margin-top: 7vh;
  		}

  		@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
			height: 90vh;
			margin-top:4vh;
		}

  		@include breakpoint(small only) {

	  		margin-top: 7em;
		    position: absolute;
		    top: 0;
		    right: -4em;
		    height: 77vh;

		}

	}

	/*.swiper-wrapper, .swiper-slide {
		transition-duration: 0ms !important;
	}*/

	.swiper-slide-active {

		.guitar-image {
			opacity: 1 !important;
			-webkit-transition: all 0.1s; /* Safari */
  		transition: all 0.1s;
		}
	}

	.scroll-down {
			text-align: left;
			left: 2.5em;
			@include breakpoint(small only) {
				left: 1.5em;
				bottom: 41px;
			}
			span {
				    margin: 10px 10px 0px 55px;

				    @include breakpoint(small only) {
				    	margin-left: 40px;
				    }
			}
		}

	.content {
	    display: inline-block;
	    width: 33%;
	    margin-top: 18vh;
	    float: left;
	    padding-right: 1em;
	    padding-left: 3.5em;
	    background-color: black;

	    @include breakpoint(medium down) {
	    	margin-top: 12vh;
	    	width: 38%;
	    }

	    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
			
			margin-top:16vh;
		}



	    @include breakpoint(small only) {
	    	width: 81%;
	    	padding-left: 1.5em;
	    }

		img {
			margin-bottom: 2em;
    		height: 40vh;
    		margin-left: -12px;

    		@include breakpoint(medium down) {
    			margin-left: -6px;
    			height: 21vh;
    		}

    		@include breakpoint(small only) {
    			margin-bottom: 1em;
    			margin-top: 3em;
    			height: 34vh;
    			
    		}
		}

		.series-image {
			width: 40%;
			float: left;
		}


		h1 {
			color: white;
			font-size: 60px;
			line-height: 60px;
		}

		p {
			color: white;
			    max-width: 80%;

			@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
		  		
		    }

			@include breakpoint(small only) {
				display: none;
			}
		}
	}

}

.no-hero {
	height: 115px;
	min-height: 0px;

	@include breakpoint(medium down) {
		height: 96px;
	}
}

.hero-merch {
	
	.mobile-image {
		background-size: cover;
    	background-repeat: no-repeat;
    	    background-position-y: center;

    	@include breakpoint(medium down) {
    		    background-position: 27%;
    	   
		    background-repeat: no-repeat;

		 }

		 	@include breakpoint(small only) {
		 		background-size: cover;
		 		background-position-x: 34%; 
		 	}


	}

}






