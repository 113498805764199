.default-page {
	padding: 5em 2em;
	overflow: hidden;

	  @include breakpoint(medium down) {
	  	padding: 2em 0em;
	  }

	  @include breakpoint(small only) {
	 	padding-bottom: 0em;
	 }

	 .default-container {

	 	padding-left: 1em;
	 	padding-right: 1em;
	 	word-wrap: break-word;

	 	h1 {
	 		margin-bottom: 41px;

	 		@include breakpoint(medium down) {
	 			margin-bottom: 25px;
	 		}
	 	}
	 }

	 .wpulike {
	 	display: none;
	 }
}

a {
	color: black;
}

a:hover, a:focus {
    color: #000000;
}

.reduce-header {
     @include breakpoint(medium down) {
      min-height: 45px;
  }
}

.grid-container {
	
	padding-left: 1.5em;
	padding-right: 1.5em;


	@include breakpoint(medium down) {
		padding-left: 0em;
		padding-right: 0em;
	}
}

.grid-margin-x {
	@include breakpoint(medium down) {
		margin-left: 0em;
		margin-right: 0em;
	}
}

.heading {
	    border-bottom: 3px solid black;
    padding-bottom: 11px;
    text-transform: uppercase;
}

.image-container {
	height: 400px;
    background-size: cover;
    background-position: center;
    margin: 0em;

    
	@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	   height: 500px;
	    }

     @include breakpoint(small only) {
	  	height: 250px;
	  }

}

.logo-text {
	font-size: 26px !important;
    color: white;
}

.menu .is-active > a {
    background: #ffffff;
    color: #000000;
    margin-right: 10px;
    margin-left: 10px;
}

/* This hides the elements before they appear on screen. Prevents the aos flash. Only needs to be used in the Heros. */
.aos-visibility {
	visibility: hidden;

	/*@include breakpoint(medium down) {
		visibility: visible;
	}
}*/}

.aos-opacity {
	opacity: 0;

	 /*@include breakpoint(landscape) {
	 	opacity: 1;
	 }*/

	/*@include breakpoint(medium down) {
		opacity: 1;
	}*/
}


.full-play-button {
	img {
		max-width: 50px;
	margin-bottom: 1em;
	



	}
	
}

/* Full Width Video Section */
.video-title-section {
		margin-top: 4em;

		@include breakpoint(medium down) {
			margin-top: 1em;
			padding: 0em 0em;
		}

		@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
		    padding: 0em 1em;
		    }

		@include breakpoint(small only) {
			padding: 0em;
			margin-top: 0em;
		}
	}

	.video-section {
    	max-width: 100%;
    	padding: 0px;
    	position: relative;
    	    height: 683px;

    	    @include breakpoint(medium down) {
    	    	height: 450px;
    	    }

    	    @include breakpoint (small only) {
    	    	height: 250px;
    	    }

    	    .video-text {
    	    	    position: absolute;
				    bottom: 6em;
				    z-index: 999;
				    left: 0;
				    right: 0;
				    margin: auto;
				    text-align: center;
				    color: white;
				    width: 35em;
				    max-width: 100%;

    	    	h3 {
    	    		text-transform: uppercase;
    	    		font-size: 61px;

    	    		@include breakpoint(small only) {
    	    			font-size: 35px;
    					line-height: 35px;
    	    		}

    	    	}

    	    	p {
    	    		@include breakpoint(small only) {
    	    			display: none;
    	    		}

    	    	}
    	    }


    	.video-wrapper {
		   position: absolute;
		   top: 0;
		   left: 0;
		   width: 100%;
		   height: 650px;
		   z-index: -1;
		   overflow: hidden;
		   margin: 2em 0em 6em;
		    background-color: black;

		     @include breakpoint(medium down) {
		     	background-color: white;
		     	height: 450px;
		     	margin: 0em;
		     	 z-index: 9;
		     }


			@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
		   		height: 540px;
		   		margin-top:2em;
		   		padding-bottom:2em;
		    }

		     @include breakpoint(small only) {
		     	height: 250px;
		     }
		}
		.video-wrapper iframe {
		   width: 100vw;
		   height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
		   min-height: 100vh;
		   min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
		   position: absolute;
		   top: 50%;
		   left: 50%;
		   transform: translate(-50%, -50%);

		   @include breakpoint(medium down) {
		   	  width: 100%;
			    height: 100%;
			    min-height: 100%;
			    min-width: 100%;
			    position: relative;
			    top: 0;
			    left: 0;
			    -webkit-transform: none;
			    -ms-transform: none;
			    transform: none;
		   }

		}

		.full-play-button {
			position: absolute;
		       bottom: 12.4em;
		    z-index: 9999;
		    left: 0;
		    right: 0;
		    margin: auto;
		    text-align: center;

		  /*  @include breakpoint(landscape) {
				bottom: 12em;
			}*/

		    @include breakpoint(medium down) {
		    	display: none;
		    }

		    img {
		    	max-width: 100px;
		    	width: 100px;
		    	height: 100px;
		    }

		    .img2 {
		    	display: none;
		    }
		}
	}

	.img2 {
		display: none;
		position: absolute;
	    z-index: 9999;
	    left: 0;
	    right: 0;
	    margin: auto;
	    max-width: 100px;
	    top: 40%;
	    
	}

	.img3 {
		display: none;
		position: absolute;
	    z-index: 9999;
	    left: 0;
	    right: 0;
	    margin: auto;
	    max-width: 100px;
	    top: 40%;
	    
	}


	.full-image-section {
    	max-width: 100%;
    	padding: 0px;
    	position: relative;
    	    height: 700px;
    	    background-size: cover;
    	    background-position: center;
    	    margin-top: 1em;


    	    @include breakpoint(medium down) {
    	    	height: 500px;
    	    }

    	    @include breakpoint (small only) {
    	    	height: 350px;
    	    }

    	    .text {
    	    	    position: absolute;
				    bottom: 6em;
				    z-index: 999;
				    left: 0;
				    right: 0;
				    margin: auto;
				    text-align: center;
				    color: white;
				    width: 35em;
				    max-width: 100%;

				     @include breakpoint(medium down) {
				     	bottom: 2em;
				     }

    	    	h3 {
    	    		text-transform: uppercase;
    	    		font-size: 61px;

    	    		@include breakpoint(medium down) {
    	    			margin-bottom: 1em;
    	    		}	

    	    		@include breakpoint(small only) {
    	    			font-size: 47px;
					    line-height: 47px;
					    margin-bottom: 1.2em;


    	    		}

    	    	}

    	    	p {
    	    		@include breakpoint(medium down) {
    	    			display: none;
    	    		}

    	    	}
    	    }
		
	}

.jarallax {
	height: 100%;
}

.four-four-page {

	background-color: black;
    color: white;
    margin-bottom: 4em;
    padding: 8em 1.5em 10em;

    @include breakpoint(medium down) {
    	padding: 5em 1.5em 7em;
    }

    @include breakpoint(small only) {
    	padding: 2em 0em 3em;
    	margin-bottom: 0em;
    }

	.default-container h1 {
		margin-bottom: 0px;

		@include breakpoint(medium down) {
			margin-top: 0em;
		}
	}

	h2 {
		@include breakpoint(medium down) {
			font-size: 27px;
		    line-height: 30px;
		    margin-top: 14px;
		    margin-bottom: 27px;
		  }
	}

	.guitar {
		float: left;
    	max-width: 200px;
    	margin-right: 1em;
	}
}


//select {
//	width: initial !important;
//	margin-right: 15px;
//	min-width: 150px;
//}