.contact-page {

	    margin-bottom: -6em;
	    position: relative;

	    @include breakpoint(medium down) {
	    	margin-bottom: 0em;
	    	padding: 2em 1em 0em;
	    }

	     @include breakpoint(small only) {
	     	padding-bottom: 3em;
	     }


	     h1 {
	     	font-size: 95px;
    		line-height: 95px;

    		@include breakpoint(medium down) {
				font-size: 63px;
				line-height: 63px;
			}

			@include breakpoint(small only) {
				font-size: 41px;
				line-height: 41px;
			}
	     }
	
	.contact-form-container {
		min-height: 850px;
		padding-right: 3em;
		padding-bottom: 3em;

		select {
			background-position: right;
		}

		 @include breakpoint(medium down) {
	    	padding-right: 0em;
	    	min-height: 0px;
	    	padding-bottom: 0em;
	    }

	    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
			width: 45%;
			display: inline-block;
			float:left;
		}

		.enquiry-text {
			padding-bottom: 2em;
		}
	}

	.disable-me {
		pointer-events: none;
		opacity: 0.2;
	}

	.dealer-form {
		display: block;
	}

	.customer-form {
		display: none;

	}

	.press-form {
		display: none;
	}

	.artist-form {
		display: none;
	}

	[type='text'], [type='password'], select, [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
		border: 1px solid black;
    min-height: 50px;
    padding: 10px 15px;
	}

	.heading {
		margin-top: 0.7em;



		&:first-child {
			margin-top:0px;
		}
	}

	.button {
		margin-bottom: 0px;
	}

	.fa {
		font-size: 30px;
    	margin-right: 12px;
    	    margin-top: 10px;

    	@include breakpoint(small only) {
		    font-size: 27px;
		    margin-right: 6px;
		    margin-bottom: 0.5em;
		    margin-top: 8px;
		}
	}

	.map-container {
		max-width: 100%;
	    position: absolute;
	    right: 0;
	    left: 0;
	    top: 0;
	    bottom: 0;
	    height: 100%;
	    padding: 0px;
	    z-index: -1;

	     @include breakpoint(medium down) {
	     	display: none;
	     }

	    .grid-x {
	    	height: 100%;
	    }

	    .map {
	    	 height: 100%;
		    background-size: cover;
		    background-position: right;
	    }
	}

	.submit-password-container {
		width: 100%;
		display: block;
		float: left;
		margin-bottom: 1em;
		margin-top: 10px;

		 @include breakpoint(medium down) {
	     	float: none;
	     	margin-top: 1em;
	     }
	}

	.download-button {
		display: none;
		margin-top: 0px;

		@include breakpoint(small only) {
		    width: 100%;
		    float: left;
		    padding: 16px 0px;
		    font-size: 17px;
		   }

	}

	.error-password {
		display: none;
	    color: #c72d2d;

	    width: 100%;
	    margin-bottom: 20px;
	    margin-top: -22px;
	    font-size: 12px;
	    float: left;

	    @include breakpoint(small only) {
	    	margin-top: -7px;
	    }
	    
	}

	.contact-details-container {
		    padding: 0em 0em 1em 5em;

		    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
				width: 45%;
			    display: inline-block;
			    float: left;
			    padding-left: 2em !important;
			}

		    @include breakpoint(medium down) {
		     	padding: 0em;
		     	padding-bottom: 2em;
		     }

		     h4 {
		     	@include breakpoint(small only) {
		     		font-size: 25px;
				    line-height: 22px;
				    margin-bottom: 13px;
				    margin-top: 0.5em;

		     	}
		     }


		    .container {
		    	background-color: rgba(255, 255, 255, 0.81);
			    height: 97%;
			    padding: 2.5em 3em;
			        max-height: 850px;

			     @include breakpoint(medium down) {
			     	padding: 0em;
			     	max-height: none;
			     }
		    
			       input {
			       	display: inline-block;
			       	width: 50%;
			       	float: left;
			       	    height: 52px;

			       	    @include breakpoint(small only) {
			       	    	width: 100%;
			       	    }
			       }

			       .press-pack-button {
			       	display: inline-block;
			       	margin-top: 0px;
			       	border-width: 1px;
			       	border-left: 0px;

			       	@include breakpoint(small only) {
			       		display: inline-block;
					    margin-top: -7px;
					    border-width: 1px;
					    width: 100%;
					    font-size: 18px;
					    padding: 15px;
					    background-color: black;
					    color: white;

					}

			       }

		    }

		    .heading {
		    	margin-bottom: 25px;

		    	@include breakpoint(medium down) {
					margin-top: 30px;
					margin-left: 0px;
				}

				 @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
					margin-top:0px;
				}

		    }

		    .downloads-header {
		    	 @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
					margin-top: 1em;
				}	
		    }
	}

	#form-dropdown {
		background-position: 100%;
		margin-top: 1em;

		@include breakpoint(small only) {
			font-size: 14px;
		}
	}

	span.wpcf7-not-valid-tip {
		color: #c72d2d;
		font-size: 12px;
		    margin-top: -12px;
	}

	.wpcf7-not-valid {
		border-color: #c72d2d;
	}

	div.wpcf7-response-output {
		border-top: 0px !important;
	    border-right: 0px !important;
	    border-bottom: 0px !important;
	    background-color: #f6f6f6;
	    padding: 19px;
	    font-size: 14px;
	    margin: 25px 0px 10px;

	    @include breakpoint(small only) {
	    	font-size: 12px;
	    	padding: 13px 16px;
	    }
	}

	div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
		border: 12px solid #d90c22;
	}

	div.wpcf7-mail-sent-ng, div.wpcf7-aborted {
		border: 12px solid #d90c22;
	}

	.wpcf7-submit {
		margin-top: 0px;
	}

	div.wpcf7 .ajax-loader {
		margin-left: 19px;
	}

	.downloads-container {
		.fa {
			font-size: 18px;
			margin-right: 5px;
			margin-top: 0px;
		}

		a:hover {
			font-weight: bold;
		}
	}

}

.contact-page [type='text'], .contact-page [type='password'], .contact-page select, .contact-page [type='date'], .contact-page [type='datetime'], .contact-page [type='datetime-local'], .contact-page [type='month'], .contact-page [type='week'], .contact-page [type='email'], .contact-page [type='number'], .contact-page [type='search'], .contact-page [type='tel'], .contact-page [type='time'], .contact-page [type='url'], .contact-page [type='color'], .contact-page textarea {
		@include breakpoint(small only) {
			font-size: 14px;
		}
	}