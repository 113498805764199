@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

.admin {

  * {
    font-family: 'Roboto', 'Arial', 'Tahoma', sans-serif;
  }

  padding-top: 3rem;
  min-height: 70vh;

  h2 {
    padding-bottom: 20px;
  }

  .button {
    font-size: 15px;
    padding: 6px 12px;
    margin-right: 5px;
    letter-spacing: initial;

  }

  .approve-button {
    width: 100%;
    font-size: 21px;
    padding: 20px 30px;
    margin-right: initial;
    letter-spacing: initial;
    font-weight: bold;
  }

  fieldset {
    margin-bottom: 40px;
  }

  input, select, textarea {
    background: #f3f3f3;
  }

}