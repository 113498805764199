.single-guitar {

	padding: 5em 0em;
	position: relative;

	 @include breakpoint(small only) {
	 	padding: 2em 0em 0em;
	 }

	 .heading {
	 	@include breakpoint(small only) {
	 		width: 94%;
	 	}
	 }

	.swiper-2-container {
		padding: 2em 0em;

		 @include breakpoint(medium down) {
		 	padding: 2em 1em;
		 }

		.image {
			height: 240px;
			background-size: cover;
			background-position: center;
		}
	}

	.swiper-container-2 {

		 @include breakpoint(medium down) {
		 	overflow: hidden;
		 }

	}

	.video-section {
		margin-bottom: 5em;
	}

	.guitar-spec-container {

		border-right: 1px solid #eaeaea;
		padding: 0em;
		-webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;

      @include breakpoint(medium down) {
		 	padding: 0em 2em;
		 	margin-bottom: 2em;
		 }

		 @include breakpoint(small only) {
		 	padding: 0em 1em;
		 	margin-bottom: 2em;
		 	border-right: 0px;
		 }

		h3 {
			font-size: 44px;
			margin-bottom: 1em;
			text-transform: uppercase;
		}

		.button {
			position: relative;
			padding-left: 40px;

			span {
				position: absolute;
				left: 20px;
				font-size: 30px;
				    line-height: 17px;
			}
		}	

		.dark-button {
			background-color: black;
			color: white;

			span {
				color: white;
			}
		}

		img {
		    max-width: 80%;
		    margin: auto;
		    display: block;

		}

		.image {
			width: 100%; height: 100px;
			background-position: center;
			background-size: cover;
			display: inline-block;
		}

		.soundboard {
			margin-top: 2em;
			display: inline-block;
			width: 50%;
			float: left;
    		border-right: 24px solid white;
    		position: relative;

    		p {
    			margin-top: -6px;
    			font-size: 12px;
    		}

    		img {
    			position: absolute;
			    right: 0px;
			    width: 25px;
			    top: 14px;
    		}
		}

		&:last-child {
			border:0px;
		}

		&:nth-child(2) {

			 @include breakpoint(medium down) {
			 	border-right: 0px;
			 }

		}
	}

	.hide-spec {
		opacity: 0.4;
		-webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;

	}

	.guitar-spec {

		padding-top: 3em;

		 @include breakpoint(small only) {
		 	margin-top: 0em;
		 	padding-top: 1em;
		 }

		.heading {
			margin-bottom: 1em;
		}

		.feature-info {
			display: inline-block;
			float: left;
			position: relative;
			z-index: 99;

			p {
				width: 55%;
			}
			
		}

		#spec {

			@include breakpoint(medium down) {
			 	padding-top: 2em;
			 }

			 @include breakpoint(small only) {
			 	padding-top: 0em;
			 }
			
		}

		.feature-guitar {
			display: inline-block;
			float: left;
			position: absolute;
			right: 13%;
    		width: 21%;
		}

		.feature-container {
			position: relative;
		}

		.feature {
			position: relative;

			&:after {
				position:absolute;
				content: '';
				border-bottom: 1px solid $sheeran-yellow;
				height: 1px;
				width: 66%;
			    bottom: 18px;
			    left: 100px;
			}
		}

		.feature-1 {
			margin-top: 1em;
		}

		.feature-2 {
			margin-top: 1em;
		}

		.feature-3 {	
			margin-top: 1em;
		}

		.full-spec {
			margin-top: 3em;
			display: none;
			margin-bottom: 3em;

			h2 span {
				display: inline-block;
			    width: 47px;
			    float: right;
			    border: 2px solid black;
			    padding: 11px;
			    height: 48px;
			    cursor: pointer;

			    @include breakpoint(small only) {
			    	display: inline-block;
				    width: 36px;
				    float: left;
				    border: 1px solid black;
				    padding: 7px 9px;
				    height: 33px;
				    margin-right: 15px;
			    }

			    img {
			    	    vertical-align: top;
			    }
			}

			.heading {
				@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
					width: 97%;
				}
			}

			.five-piece-text {

				padding: 0em 2.6em 0em;

				@include breakpoint(medium down) {
					padding: 1em 3.6em 0em;
				}

				@include breakpoint(small only) {
					padding: 1em 1.6em 0em;
				}
			}

			.content {
				

				h3,p {
					display: inline-block;
					width: 49%;
				}

				p {
					position: relative;

					img {
						position: absolute;
					    position: absolute;
					    left: 127px;
					    max-width: 25px;
					    top: 5px;



					    @include breakpoint(small only) {

						    position: absolute;
						    left: 131px;
						    max-width: 25px;
						    top: 6px;
						}

					}
				}

				.five {
					   

					     @include breakpoint(small only) {
					     	padding-left: 0px;
					     }
				}



				@include breakpoint(medium down) {
					h3 {
						width: 32%;
					}

					p {
						width: 67%;
					}
				}

				@include breakpoint(small only) {

					h3 {
						 width: 100%;
					    font-size: 23px;
					    margin-bottom: 0px;
					}

					p {
						width: 100%;
					}
				}

				   

			}

			.container {
				padding-right: 1em;
			    border-right: 1px solid #bebebe;
			    padding-left: 2em;
			    margin-bottom: 3em;

			    @include breakpoint(medium down) {
			    	margin-bottom: 0em;
			    	border:0px;
			    }

			     @include breakpoint(small only) {
			     	padding-left: 1em;
			     }

				&:last-child {
					border-right: 0px;
				}
			}

			.spec-title {
				padding-left: 30px;
				margin-bottom: 20px;
			}
		}

		.soundboard-back {
			img {
				display: inline-block;
				float: left;
				margin-right: 25px;
				max-width: 151px;
    			margin-top: 1em;
			}

			.soundboard, .back {
				display: block;
				width: 100%;
				float: left;
				margin-bottom: 2em;
			}

			.further-description {

				    margin-top: 20px;

				    .image {
				    	background-size: cover;
					    width: 40px;
					    height: 40px;
					    background-position: center;
					    border-radius: 50%;
					    display: inline-block;
					    float: left;
					    margin-right: 10px;

				    }

				    p {
				    	display: inline-block;
				    	margin-top: 10px;
				    }
			}
		}


	}

	.further-information {
		padding-bottom: 3em;
		p {
			width: 70%;
			margin-top: 3em;
		}
	}

	.full-image-section {
		margin-top: 0em;
	}

	.components-section {
		background-color: black;
		margin:5em 0em 0em;
		padding: 4em 0em 4em;

		 @include breakpoint(medium down) {
		 	padding: 2em 0em 1em;
		 	margin: 0em 0em -1em;
		 }

		h2 , p {
			color: white;
		}

		p {
			@include breakpoint(small only) {
			    font-size: 12px;
			   }
		}

		.button {
			    border: 2px solid white;
		}

		.heading {
			border-color: white;
		}

		.part-container {
			width: 14.5%;
		    display: inline-block;
		    padding: 1em;
		    text-align: center;
		    float: left;

		     @include breakpoint(medium down) {
			 	width: 19.5%;
			 }

			 @include breakpoint(small only) {
			 	width: 49.5%;
			 	    padding: 1em 0em;
			 }

			h3 {
				color: white;
				margin-bottom: 5px;
				margin-top: 20px;
				line-height: 33px;

				@include breakpoint(small only) {
				 	color: white;
				    margin-bottom: 5px;
				    margin-top: 16px;
				    line-height: 33px;
				    font-size: 28px;
				 }

				
			}

			&:last-child {
				width: 24%;
			    border-left: 1px solid white;
			    margin-left: 3%;

			     @include breakpoint(medium down) {
			     	width: 100%;
			     	border-left:0px;
			     	margin-left: 0px;
			     	padding-top: 0px;
			     }

			     @include breakpoint(small only) {
			     	width: 49%;
			     	    padding-top: 2em;
			     }
			}

			&:nth-last-child(2) {
				img {
					margin-top: 18px;
				    max-width: 116px;
				    margin-bottom: -10px;
    				}
			}

		}

	}


	.quote-section {
		position: relative;
		margin-top: 3em;

		    @include breakpoint(medium down) {
		    	margin-top: 2em;
		    	padding: 1em;
		    }	

		     @include breakpoint(small only) {
		     	margin-top: 0em;
		     }

		h2 {
			text-transform: uppercase;
		    font-size: 64px;
    		line-height: 64px;

    		 @include breakpoint(medium down) {
    		 	 font-size: 50px;
    			line-height: 59px;
    		 }

    		 @include breakpoint(small only) {
    		 	font-size: 32px;
    			line-height: 35px;
    		 }
    		   
		}

		.quote-1 {
			margin:1em 0em;

			 @include breakpoint(small only) {
			 	width: 50px;
			 }
		}

		.quote-2 {
			margin:1em 0em;
			@include breakpoint(small only) {
			 	width: 50px;
			 }
		}

		.quote-container {
			background-color: white;
			position: relative;
		}

		.george-image {

			img {
				width: 77%;
			    padding: 2em;
			    margin-top: 5em;
			    position: relative;
    			z-index: 999;

    			@include breakpoint(medium down) {
			        width: 66%;
				    padding: 0em 1em;
				    margin-top: 0em;
				    position: relative;
				    z-index: 999;
				}

				@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
						width: 45%;
						margin-left:2em;
					}

				@include breakpoint(small only) {
					display: none;
				}
			}

		}

		.device-story-1 {

		    height: 78%;

		    @include breakpoint(medium down) {
		    	display: none;
		    }

		    span {
		    	height: 81%;
		    }

			.one {
				    margin-left: 56px;
			}

			.two {
				margin-left: 20px;
			}

			.three {
				margin-left: 40px;
			}
		}

		.device-story-2 {

			height: 78%;

			 @include breakpoint(medium down) {
			 	height: 40%;
			 }

			span {
		    	height: 81%;
		    }

		    .seven{
		    	margin-right: 50px;
		    }

		    .eight{
		    	margin-right: 30px;
		    }

		    .ten{
		    	margin-right: 50px;
		    }

		    .eleven{
		    	margin-right: 30px;
		    }

		}
	}
}