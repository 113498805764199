header {
	background-color: rgba(254, 254, 254, 0);
    z-index: 99999;
    left: 0;
    right: 0;
    position: absolute;
    overflow: hidden;

    @include breakpoint(medium down) {
    	position: fixed;

    }

    @include breakpoint(medium down) {
      min-height: 90px;

    }



    .grid-container {
      background-color: rgba(254, 254, 254, 0);
    }

    .top-bar, .top-bar ul {
      background-color: rgba(254, 254, 254, 0);
    }

    .site-title-bar {
        @include breakpoint(medium down) {
            padding: 0.7em 0em;
        }
    }

    .logo-text {
      img {
            width: 130px;
            height: 57px;
      }
    }

    .menu-icon {
    	float: right;
    }

    .mobile-menu {
    	@include breakpoint(medium down) {
    		background-color: #2e2e2e;
    		margin-top: 1.5em;
    	}
    }


    .nav-dealers {

      position: relative;

        @include breakpoint(medium down) {

          margin-top: 3em;
          max-width: 202px;
          margin-left: auto;
          margin-right: auto !important;

          &:before {
            content: '';
             background-image: url(../../assets/images/map-pin.svg);
             position: relative;
            width: 35px;
            height: 27px;
            background-repeat: no-repeat;
            background-size: contain;
            float: left;
            margin-top: 5px;
          }
         

          &:after {
              content: '';
              width: 160px;
              height: 2px;
              background-color: black;
              position: absolute;
              top: -1.5em;
              left: 0;
              right: 0;
              margin: auto;
          }
        }

         @include breakpoint(medium only) {
          max-width: 270px;

           &:before {
            margin-top:10px;
            height: 36px;
           }
         }

    }

    .mobile-navigation {


        @include breakpoint(medium down) {
            padding: 0em ;
             background-color: rgba(255, 255, 255, 0) !important;
           
            ul {
                background-color: rgba(255, 255, 255, 0);
                list-style: none;

                li a {
                    border: 0px !important;
                    border-radius: 0px !important;
                    color: black;
                }
            }
        }

          @include breakpoint(small only) {
            padding: 0em;
          }

          .mobile-background-image {
             
              background-size: contain;
              background-repeat: no-repeat;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              display: none;

               @include breakpoint(medium only) {
                background-size: cover;
                 background-image: url(../../assets/images/tablet-background.png);
               }

                 @include breakpoint(small only) {
                    background-image: url(../../assets/images/mobile-background.png);
                 }

              @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
                background-size: contain;
                background-position: center;
                 background-image: url(../../assets/images/tablet-background-landscape.png);
              }
          }
    }

    .remove-background {
       @include breakpoint(medium down) {
        background-color: white !important;
      }
    }

    .top-bar .menu .is-active a {
      color: #ffffff;
      border-bottom: 2px solid white;
      font-family: 'Tungsten bold';
      letter-spacing: 0.5px;

       @include breakpoint(medium down) {
        color: black;
        margin: 0px;
       }
    }



    #nav-icon3 {
      width: 28px;
      height: 20px;
      position: relative;
      margin: 0px auto;
      float: right;
      margin-top: 18px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;

    }

    #nav-icon3 span {
      display: block;
      position: absolute;
       height: 4px;
      width: 100%;
      background: $black;
      border-radius: 5px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }



    /* Icon 3 */

    #nav-icon3 span:nth-child(1) {
      top: 0px;
    }

    #nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
      top: 8px;
    }

    #nav-icon3 span:nth-child(4) {
      top: 16px;
    }

    #nav-icon3.open span:nth-child(1) {
      top: 10px;
      width: 0%;
      left: 50%;
    }

    #nav-icon3.open span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    #nav-icon3.open span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    #nav-icon3.open span:nth-child(4) {
      top: 10px;
      width: 0%;
      left: 50%;
    }
}

.top-bar, .top-bar ul {
	background-color:$white;
    padding: 12px 0px;

    @include breakpoint(small only) {
    	padding: 0px;
    }

    .top-bar-right {
        @include breakpoint(medium down) {
           width: 100%;
        }
    }

    .top-bar-right li {

          margin-right: 0px;
          padding: 10px 0px;
          display: inline-block;

         @include breakpoint(medium down) {
                width: 100%;
                margin-right: 0px;
                   
    padding: 5px 0px;
            }

    	&:not(:last-child) {
    		border-right: 0px;

             @include breakpoint(medium down) {
               border-bottom: 0px solid #4e4e4e;
             }
    		
    	}

    	a {
	    	font-size: 14px;
	    font-family: "effra",sans-serif;
		    line-height: 20px;
		    border: 0px solid #0a0a0a;
		    border-radius: 0px;
		    padding: 10px 0px !important;
		    background-color: rgba(0, 157, 226, 0) !important;
        color: $white;
            text-transform: uppercase;
            margin-right: 42px;
                font-family: 'Tungsten Medium';
    font-weight: normal;
    font-size: 24px;

		    @include breakpoint(medium down) {
		    	text-align: center;
		    	padding: 9px 0px !important;
          color: $black;
          margin-right: 0px;
          font-size: 29px;
          line-height: 22px;
		    }

         @include breakpoint(small only) {
          margin-right: 0px;
         }

          @include breakpoint(medium only) {
            font-size: 37px;
            padding: 19px 0px !important;
          }
    	}
    }
}

.header-logo {
	  max-width: 120px;
    margin-top: -13px;

    @include breakpoint(medium down) {
      margin-top: 0px;
      margin-bottom: 10px;

    }
}



.top-bar .sub-menu {
  position: absolute;
    background-color:#eaeaea;
    list-style: none;
    margin: 0px;
    min-width: 250px;
    padding: 0px;
    top: 96px;
    border-top: 4px solid #00a0e7;
    display: none;

    @include breakpoint(medium down) {
      position: relative;
      top: 0;
    }

        
        li {

          padding: 0px;

          a {
         padding: 30px 20px !important;
          border-bottom: 1px solid #cccccc !important;
          margin: 0px;
          color: black !important;

           @include breakpoint(medium down) {
              padding: 17px 19px !important;

            }
            }

            &:hover {
            background-color: #cccccc;
          }

          }
    }

    @include breakpoint(medium down) {


header .mobile-navigation {
    padding: 0em;
    display: block ;
    width: 0px;
    position: absolute;
    background-color: #ffffff;
        top: 0;
        left: 100%;
    bottom: 0;

   
}

header .mobile-toggle {
  width: 100%;
  left: 0;

   -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
}

header #nav-icon3 {
  z-index: 999999;
  background-color: white;
      margin: 0px;
     
}

header #nav-icon3 span {
  height: 2px;
}

.hamburger-container {
  background-color: white;
  position: fixed;
  right: 0;
  top: 24px;
  padding: 12px;
  z-index: 99;
   
}

.hambugrer-sticky {
  top: 0px;
   border: 1px solid black;
  border-right: 0px;
  border-top: 0px;
  -webkit-transition: .1s ease-in-out;
  -moz-transition: .1s ease-in-out;
  -o-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
}

.title-bar-title a {
    font-size: 1rem;
    color: #B9B9B9;
    color: white;
    font-size: 35px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.title-bar-title {
  padding: 8px 20px;
}

.top-bar-right {
  height: 100%;
  opacity: 0;
   

  .menu {
    height: 100%;
    
    ul {
      width: 100%;
      background-color: #ffffff;
      margin: 0px;
       margin: 24vh auto;

      li {
        text-align: center;
           

            a {
              text-align: center;
              color: black;
            }
      }
    }  

  }
}

.menu-header-menu-container {
  margin-top: 23vh;

   @include breakpoint(medium only) {
    margin-top: 22vh;
   }

   @include breakpoint(small only) {
    margin-top: 18vh;
   }
}

.open-menu {
  opacity: 1;

   -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      transition-delay: .45s;
}

}

.top-bar .top-bar-title a {
  color: white;
}

.full-height-menu {
  @include breakpoint(medium down) {
     -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
  }
  }

  .full-height{
    height: 100vh;
  }


.vertical-logo {
     
    position: absolute;
    z-index: 999999;
    top: 0em;
    left: 0em;
    width: 225px;

    @include breakpoint(medium down) {
      width: 163px;
      padding: 8px 9px;
    }

    img {
          padding: 0.5em;
    }
  }

  .menu .is-active > a {
    @include breakpoint(small only) {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
