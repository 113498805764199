@font-face {
  font-family: 'Tungsten Medium';
  font-weight: normal;
  src: url('../fonts/Tungsten-Medium.eot'); /* IE9+ Compat Modes */
  src: url('../fonts/Tungsten-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Tungsten-Medium.woff2') format('woff2'), /* Super modern browsers */
       url('../fonts/Tungsten-Medium.woff') format('woff'), /* Modern browsers */
       url('../fonts/Tungsten-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Tungsten-Medium.svg') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Tungsten Bold';
  font-weight: normal;
  src: url('../fonts/Tungsten-Bold.eot'); /* IE9+ Compat Modes */
  src: url('../fonts/Tungsten-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Tungsten-Bold.woff2') format('woff2'), /* Super modern browsers */
       url('../fonts/Tungsten-Bold.woff') format('woff'), /* Modern browsers */
       url('../fonts/Tungsten-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Tungsten-Bold.svg') format('svg'); /* Legacy iOS */
}


h1, h2, h3, h4 {
	font-family: 'Tungsten Medium';
    font-weight: normal;

    b {
      font-family: 'Tungsten Bold';
       font-weight: normal;
    }

    strong {
      font-family: 'Tungsten Bold';
       font-weight: normal;
    }
    
}

h4 {
   @include breakpoint(small only) {
    font-size: 25px; line-height: 22px;
   }

}

h1 {
          font-size: 113px;
    line-height: 113px;
    text-transform: uppercase;

    @include breakpoint(medium down) {
      font-size: 78px;
      line-height: 78px;
    }

    @include breakpoint(small only) {
      font-size: 62px;
      line-height: 62px;
    }

        
}

.heading {
	  font-family: 'Tungsten Medium';
    font-style: normal;
    font-size: 49px;
    line-height: 49px;

     @include breakpoint(medium down) {
       width: 95%;
      margin: auto;
     }

     @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
     width:100%;
    }

    @include breakpoint(small only) {
      font-size: 32px;
      line-height: 32px;

      width: 88%;
      margin: auto;
    }

    b {
    	 font-family: 'Tungsten Bold';
    	 font-weight: normal;
    }
}


p, li {
	font-size: 14px;
    font-family: itc-american-typewriter, serif;
    font-style: normal;
    line-height: 20px;
}