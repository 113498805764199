.merchandise-page {

	@include breakpoint(medium down) {
		padding: 3em 1em;
	}

	.heading {
		@include breakpoint(medium down) {
			width: 100%;
		}
	}


	.item-container {

		border: 1px solid #ebebeb;
		margin-top: 2em;

		img {
				width: 100%;
				display: block;
				padding: 0em;
				margin: auto;
				background-color: #ebebeb;
			}

		.text-container {
			
			    padding: 1em;
			    border-top: 1px solid #ebebeb;

			h3 {
				display: inline-block;

				@include breakpoint(small only) {
					font-size: 26px;
    				margin-top: 6px;
				}

				    
			}

			.wpulike {
				display: inline-block;
    			float: right;
			}

		}

	}
}


	#myModal {
		display: none;
		position: fixed;
	    background-color: white;
	    border: 2px solid black;
	    padding: 3em 3em 1em;
	    top: 33%;
	    left: 0;
	    right: 0;
	    max-width: 1300px;
	    margin: auto;
	    z-index: 99999999;

	    @include breakpoint(medium down) {
	    	padding: 2em 1em 2em;
	    }

	    @include breakpoint(small only) {
	    	top: 4em;
	    	padding: 0em 1em;

    	    .register-your-interest {
    	    	background-image: none;
    	    }

    	    form {
    	    	padding: 1em 0em 0em;
    	    }

    	    h2 {
    	    	font-size: 36px;
			    line-height: 39px;
			   
    	    }

    	   
	    }

	    .register-your-interest form {
	    	@include breakpoint(small only) {
	    		display: block;
	    	}
	    }
	}

	.close-button-modal {
		background-color: black;
	    color: white;
	    padding: 15px 20px;
	    font-size: 22px;
	    position: absolute;
	    top: 0;
	    right: 0;

	    @include breakpoint(small only) {	
	    
	        padding: 7px 14px;
		    font-size: 22px;
		    position: absolute;
		    top: 0;
		    right: 0;
		    border: 2px solid black;
		    background-color: white;
		    color: black;
		    border-top: 0px;
		    border-right: 0px;

		}
	}