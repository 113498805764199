.our-story-page {

	padding: 4em 0em;

	@include breakpoint(medium down) {
		padding: 2.5em 0em;
	}

	@include breakpoint(small only) {
		padding: 2.5em 0em 0em;
	}

	.section-1 {

		position: relative;

		.content {
			padding: 3em 0em;

			@include breakpoint(medium down) {
				padding: 3em 0em 1em;
			}

			@include breakpoint(medium down) {
				padding: 3em 0em 0em;
			}
			

			.hammering-title {
				@include breakpoint(small only) {
					padding: 0em 1em;
				}
			}

			.text-container {
				position: relative;
				background-color: white;

				.text {
					
					padding: 0em 3em 0em 2em;

					 @include breakpoint(medium down) {
					 	padding: 0em 1em 0em 1em;
					 }

					 @include breakpoint(small only) {
					 	position: relative;
					 	margin-top: 1em;

					 }
				}
			}
		}

		.device-story-2 {
			@include breakpoint(small only) {
			    height: 24%;
			    bottom: 0em;
			    top: 8%;

			    .four {
			    	    margin-right: 29px;
			    }
			 }
		}

		h3 {
			font-size: 177px;
		    text-transform: uppercase;
		    line-height: 134px;

		    @include breakpoint(medium down) {
		        font-size: 111px;
			    text-transform: uppercase;
			    line-height: 90px;
			    padding: 20px;
			 }

			  @include breakpoint(small only) {
			  	font-size: 74px;
			    line-height: 67px;
			    max-width: 87%;
			    padding: 0px;
			  }

			    
		}

	}

	.section-2 {
		margin-top: 3em;

		@include breakpoint(small only) {
			margin-top: 2em;
		}
	}

	
	.section-3-container {

		position: relative;

		.full-width-timeline-bar {
			background-color: #595959;
			width: 100%;
		    height: 2px;
		    position: absolute;
		    top: 142px;

		    @include breakpoint(medium down) { 
		    	 top: 114px;
		    }

		    @include breakpoint(small only) { 
		     	height: 2px;
			    margin-bottom: 0em;
			    top: auto;
    			bottom: 130px;
		     }

		       
		}

		.full-width-timeline-bar-mobile {
			background-color: #848484;
			width: 100%;
		    height: 20px;
		    position: absolute;
		    top: 142px;

		    @include breakpoint(medium down) { 
		    	 top: 110px;
		    }

		    @include breakpoint(small only) { 
		     	height: 2px;
		     	margin-bottom: 0em;
		     	top: 8.3em;
		     }

		       
		}
	}

	.section-3 {
	
		.swiper-timeline-container {
			@include breakpoint(medium down) { 
				padding: 0em 1em;
			}

		}

		.swiper-pagination-bullet {
			opacity: 1;
		}

		.swiper-container {

			overflow: visible;

			@include breakpoint(medium down) { 
				overflow: hidden;
			}

			.swiper-wrapper {
	    		margin: auto;
	    		padding-bottom: 6em;
	    		padding-top: 2em;

	    		@include breakpoint(medium down) { 
	    			padding-bottom: 3em;
    				padding-top: 1em;
	    		}

	    		@include breakpoint(small only) { 
	    			padding-bottom: 0em;
	    			padding-top: 1em;
	    		}

	    		.swiper-slide {
	    			opacity: 0.3;
	    			color: white;
	    			margin-top: 4em;
	    			-webkit-transition: .5s ease-in-out;
				      -moz-transition: .5s ease-in-out;
				      -o-transition: .5s ease-in-out;
				      transition: .5s ease-in-out;

				      @include breakpoint(medium down) { 
				      	margin-top: 3em;
				      }

				      @include breakpoint(small only) { 
				      	margin-top: 0em;
				      }

				      h2 {
				      	font-size: 90px;
					    line-height: 90px;
					    font-family: 'Tungsten Bold';
					    margin-bottom: 21px;
    					padding-bottom: 7px;
					    border-bottom: 2px solid white;

					    @include breakpoint(small only) {

						    font-size: 77px;
						    line-height: 77px;
						    margin-bottom: 0px;
						    padding-bottom: 16px;
						    border-bottom: 0px solid white;
						    margin-top: 46px;
						    -webkit-transform: rotate(90deg);
						    -moz-transform: rotate(90deg);
						    -ms-transform: rotate(90deg);
						    -o-transform: rotate(90deg);
						    transform: rotate(90deg);
						    float: left;
						    line-height: 56px;
						    margin-left: -27px;
						    color: #ffffff;
						    font-size: 84px;


						 }

				      }

				      .ed-timeline {
				      	border-color: #ffc802;

				      	span {
			      			@include breakpoint(small only) {
			      				color: #ffc802;
			      			}
				      	}
				      }

				      .text-container {
				      	@include breakpoint(small only) {
				      		display: inline-block;
					      	padding-left: 99px;
					      	margin-top: -100px;
					      }

				      }

				      p {
				      	width: 90%;

				      	@include breakpoint(small only) {
				      		width: 100%;
				      	}
				      }

				      .image-1 {
				      	height: 400px;
				      	background-size: cover;
				      	background-position: center;
				      	width: 100%;
				      	margin: 3em 0em;

				      	@include breakpoint(medium down) { 
					      margin: 1em 0em 2em;
					      height: 300px;
					      }
				      }

				      .image-2 {
				      	height: 400px;
				      	background-size: cover;
				      	background-position: center;
				      	width: 100%;
				      	margin: 3em 0em;

				      	@include breakpoint(medium down) { 
				      		height: 300px;
				      	}

				      	@include breakpoint(small only) { 
				      			height: 200px;
				      			margin: 1em 0em 0em;
				      			margin-bottom: 2em;
				      		}
				      }

				      &:nth-child(odd) {
				      	.image-1 {
				      		display: none;
				      	}
				      }

				      &:nth-child(even) {
				      	.image-2 {
				      		display: none;

				      		@include breakpoint(small only) { 
				      			display: block;
				      			margin-bottom: 2.5em;
				      		}
				      	}
				      }

				      .image-1 {
				      	@include breakpoint(small only) { 
					      display: none;
					      }
				      }
	    		}

	    		.swiper-slide-active {
	    			opacity: 1;
	    			-webkit-transition: .5s ease-in-out;
				      -moz-transition: .5s ease-in-out;
				      -o-transition: .5s ease-in-out;
				      transition: .5s ease-in-out;
	    		}

	    		.swiper-slide-next {
	    			opacity: 1;
	    			-webkit-transition: .5s ease-in-out;
			      -moz-transition: .5s ease-in-out;
			      -o-transition: .5s ease-in-out;
			      transition: .5s ease-in-out;
	    		}


			}

			.swiper-button-next, .swiper-button-prev {
				    position: absolute;
				    top: 100%;
				    width: 44px;
				    height: 27px;
				    margin-top: -12px;
				    z-index: 10;
				    cursor: pointer;
				    background-size: contain;
				    background-position: center;
				    background-repeat: no-repeat;
				    z-index: 999999;

				    @include breakpoint(medium down) {
				    	top: 97%;
				    }	

				    @include breakpoint(small only) {
				    	top: 97%;
				    }
			}

			.swiper-button-prev {
				
				left: 94%;

				@include breakpoint(medium down) {
				    	left: 88%;
				    }

				@include breakpoint(small only) {
					left: 25%;
				}
			}

			.swiper-button-next {
				
				right: 0%;

				@include breakpoint(small only) {
					right: 25%;
				}
			}

			.swiper-scrollbar {
				@include breakpoint(small only) { 
					display: none;
				}
			}

			.slider-graphic {
				    max-width: 63px;
				    display: block;
				    margin: auto;
				    margin-bottom: 5px;
			}

			.swiper-pagination {
				top: 0;
				z-index: 9999;
				bottom: 96%;

				@include breakpoint(medium down) { 
			        padding: 0em 0.5em;
			     }

			     @include breakpoint(small only) { 
			        padding: 0em;
			        top: 87%;
			        padding-left: 6%;
			     }

				span {
					width: 20px;
				    height: 15px;
				    background-color: white;
				    display: inline-block;
				    border-radius: 50%;
				    display: inline-block;
				    float: left;
				    top: 2px;
				    margin-left: 3.65%;
				    border: 4px solid #000000;
				    z-index: 9999;
				    position: relative;

				    border: 0px;
				    width: 2px;
				    border-radius: 0px;
				    margin-left: 4.8%;

				     @include breakpoint(medium down) { 
				            margin-left: 4.5%;
				            top: 5px;
				     }

				     @include breakpoint(small only) { 
				             margin-left: 3%;
						    height: 14px;
						    border: 0px;
						    top:7px;

						}
				    
				    &:nth-child(13), &:nth-child(14), &:nth-child(15), &:nth-child(17)  {
				    	background-color: #ffc802;
				    	    opacity: 1;
				    }
				}

				.swiper-pagination-bullet-active {
					    width: 2px;
					    height: 20px;

				}
			}

		}
	}

	.section-3-container {
		padding: 5em 0em;
		background-color: black;
		margin: 2em 0em;


		 @include breakpoint(medium down) { 
		 	padding: 3em 0em;
		 }

		 @include breakpoint(small only) { 
		 	padding: 0.5em 0em 3em;
		 }

		.swiper-container-horizontal > .swiper-scrollbar {
			bottom: 0;
		    background-color: #595959;
		    opacity: 1 !important;
		    border-radius: 0px;
		    height: 2px;
		    left: 0;
		    right: 0;
		    width: 92%;

		    @include breakpoint(medium down) { 
		     	width: 84%;
		     	bottom: 20px;
		     }

		     @include breakpoint(small only) { 
		     	height: 2px;
		     }
		}

		h2 {
			@include breakpoint(small only) { 
		     	color: white;
		     	border-color: white;
		     }
		}

		.swiper-scrollbar-drag {
		    border-radius: 0px;
		    height: 8px;
		    top: -3px;
		    left: 0px;
		    right: 5px;
		    background-color: white;
		}

		.dates {

			margin-bottom: 1em;

			 @include breakpoint(medium down) {   
			 	padding: 0em 1.5em;
			 }

			 @include breakpoint(small only) {   
			 	padding: 0em 0em;
			 	margin-top: 0em;
			 	margin-bottom: 4.4em;
			 }

			span {
				color: white;
			    width: 15.8%;
			    display: inline-block;
			    text-align: left;
			    font-family: 'Tungsten Medium';
			    font-weight: normal;
			    font-size: 31px;
			    position: relative;

			    @include breakpoint(medium down) {
						width: 15%;
					}

				@include breakpoint(small only) {   
				 	width: 10.2%;
    				font-size: 23px;
				    text-align: center;
				    background-color: black;
				    margin: 0em 1%;
				 }

			     &:last-child {
			     	width:2%;
			     	text-align: right;
			     	@include breakpoint(small only) {
			     		text-align: center;
			     		width: 11%;
			     	}  
			     }

			     &:after {
			     	position: absolute;
				    content: '';
				    border-right: 2px solid #595959;
				    bottom: -16px;
				    width: 2px;
				    height: 13px;
				    left: 19px;

				     @include breakpoint(medium down) {
				    	bottom: -20px;
				    }

				    @include breakpoint(small only) {
				    	bottom: -15px;
				    }
			     }
			}
		}
	}
	
}