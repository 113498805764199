/* IE10+ CSS styles go here */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
#myModal {
	width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    max-width: 90%;
}

.jarallax img {
	position: relative !important;
	left: 0 !important;
	right: 0 !important;
	height: auto !important;
	width: 100% !important;
	transform: none !important;
	overflow: visible !important;
	bottom: 0 !important;
	top: -4em !important;
	margin-top: 0px !important;

}

}