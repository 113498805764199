.the-guitars-page {

	padding: 5em 0em;

	 @include breakpoint(medium down) {
	 	padding: 1em 0em;
	 }

	  @include breakpoint(small only) {
	 	background-color: #ededed;
	 }


	.video-title-section {
	 	@include breakpoint(medium down) {
	 		padding: 0em;
	 	}
	}

	.section-1 {

		max-width: 100%;
		padding: 2em 0em;

		.content {
			width: 33%;
		    max-width: 100%;
		    padding: 0em 3em;
		    float: left;

		    @include breakpoint(medium down) {
		    	width: 100%;
		    	padding: 0em 1em;
		    }

		    img {
		    	width: 80px;
		    }

		    h3 {
		    	font-size: 72px;
			    line-height: 56px;
			    text-transform: uppercase;
			    margin: 24px 0em 30px;
		    }

		}
	

		 .image-1 {

		    	width: 41%;
			    height: 100%;
			    float: left;
			    background-position: center;
			    margin-left: -20%;
			    background-position: right;
			    background-size: cover;
			    background-repeat: no-repeat;
			    background-color: black;

			    @include breakpoint(medium down) {
			    	display: none;
			    }
		    }

		    .image-2 {
		    	width: 50%;
			    height: 100%;
			    float: left;
			    background-position: center;
			    margin-right: -20%;
			    background-size: cover;

			    @include breakpoint(medium down) {
			    	display: none;
			    }
		    }



	}


	.section-2 {
		margin-top: 2em;
		padding-bottom: 2em;

		@include breakpoint(medium down) {
			margin-top: 1em;
			
		}

		@include breakpoint(small only) {
			padding-bottom: 3em;
			margin-top: 1em;
		}

		.content {
			padding: 2em 0em 0em;
			width: 100%;
			display: block;
			position: relative;
			float: left;
    		margin-bottom: 4em;

    		@include breakpoint(medium down) {
    			margin-bottom: 0em;
    			padding: 2em 1em 0em;
    		}

    		@include breakpoint(small only) {
    			padding: 2em 1em 0em;
    		}


		.guitar-number {
			width: 13%;
    		display: inline-block;
    		float: left;

    		 @include breakpoint(medium down) {
    		 	display: none;
    		 }
    		   

    		    img {
    		    	width: 100%;
    		    }
		}

		.guitar-image {
			width: 57%;
		    display: inline-block;
		    height: 500px;
		    background-position: center;
		    background-size: cover;
	        float: left;
	        border-left: 2em solid white;
	        border-right: 2em solid white;

	         
		}

		.guitar-info {
			display: inline-block;
    		width: 37%;
    		position: relative;
    		float: left;
    		

    		@include breakpoint(medium down) {
    		 	width: 51%;
    		 }

    		 @include breakpoint(small only) {
    		    width: 100%;
			    padding: 1em 1.5em;
			    background-color: white;
			    border-radius: 0px 0px 20px 20px;
			    overflow: hidden;
    		 }

    		 h3, p, .button {
    		 	
    		 		margin-left: 8px;

    		 		@include breakpoint(medium down) {
    		 			margin-left: 0px;
    		 		}
    		 	
    		 }

    		 h3 {

    		 	text-transform: uppercase;
				font-size: 49px;
    	

    		 	@include breakpoint(small only) {
    		 		font-size: 41px;
				    line-height: 41px;
				    margin: 8px 0px 17px;
    		 	}
    		 }

    		 
    		   

    		.title {
    			z-index: -1;
			    position: relative;
			    margin-bottom: 1em;
			        width: 36%;

			    @include breakpoint(medium down) {
			    	display: none;
			    }
    		}

    		.guitar {
    			position: absolute;
			    right: 0em;
			    top: 0em;
			    z-index: -1;
			    max-height: 550px;

			    @include breakpoint(medium down) {
	    		 	max-height: 400px;
	    		 }

	    		  @include breakpoint(small only) {
	    		  	max-height: 500px;
				    position: absolute;
				    right: -8em;
				    z-index: 1;
				    top: -10em;
	    		  }

    		}

    		p {
    			max-width: 46%;

    			@include breakpoint(medium down) {
	    		 max-width: 58%;
	    		 }

	    		  @include breakpoint(small only) {
	    		 	max-width: 100%;
	    		 }
    		}
		}

		}

		
	}

	.section-2 .content:nth-child(odd) {
			.guitar-number {
				float: right;
			}

			.image-container {
				float: right;

				@include breakpoint(medium down) {
					border-left: 2em solid white;
    				border-right: 0em;
				}  
			}

			.guitar-info {
				float: right;
			}

		}

	.section-3-container {
		background-size: contain;
		background-position: center;
		
		    background-repeat: no-repeat;
		        position: relative;
	

	.section-3 {

		margin-top: 5em;
		background-color: white;

		@include breakpoint(medium down) {
			margin-top: 0em;
			margin-bottom: 0em;
		}

		@include breakpoint(small only) {
			padding-top: 1em;
		 margin-bottom: -1em;
			
		}

		.content-container {
			background-color: white;
		    position: relative;
		    z-index: 999;
		}

		.content {

			background-color: white;
			padding: 3em;
			padding-right: 0em;

			@include breakpoint(medium down) {
				 padding: 3em 1em 2em;
			}

			@include breakpoint(small only) {
				 padding: 1em;
				
				
			}

		h2 {
			font-size: 113px;
    		line-height: 87px;
		    text-transform: uppercase;
		    margin-bottom: 40px;

		    @include breakpoint(medium down) {
		    	font-size: 66px;
			    line-height: 66px;
			    text-transform: uppercase;
			    margin-bottom: 30px;
		    }

		     @include breakpoint(small only) {
		     	font-size: 73px;
    			line-height: 59px;
    			margin-bottom: 0px;
		     }

		    b {
		    	display: block;
		    }
		}

		}

		.circle-image {
			    background-size: 75%;
			background-position: left;
			background-repeat: no-repeat;
			position: relative;z-index: 99999;

			@include breakpoint(medium down) {
				min-height: 400px;
				background-size: contain;
    			    background-position: 47%;
			}

			@include breakpoint(small only) {
				min-height: 269px;
			    background-size: contain;
			    background-position: 31%;
			    margin-bottom: 1em;
			    margin-left: 0em;
			    margin-right: 0em;
			    width: 100%;

			    &:after {
			    	content: '';
			    	background-image: url(../../assets/images/Guitars/background-aero-mobile-1.png);
			    	position: absolute;
				    width: 100%;
				    height: 100%;
				    background-size: contain;
				    background-repeat: no-repeat;
				    margin-top: -6em;
				    background-position: 5em;	
			    }

			    
				
			}
		}
	}


	.device-story-1 {
	    height: 100%;
	    position: absolute;
	    left: 0;
	    bottom: 0em;

	    @include breakpoint(medium down) {
			height: 49.7%;
			overflow: hidden;
		}

	    span {
	     
	     height: 100%;
	    }

	    .one {
	    	    border-width: 188px;

	    	     @include breakpoint(medium down) {
	    	     	 border-width: 40px;
	    	     }
	    }

	    .two {
	    	border-width: 5px;
	    	margin-right: 40px;
	    }

	    .three {
	    	    border-width: 18px;
	    }
	}

	.device-story-2 {
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0em;

    @include breakpoint(medium down) {
    	overflow: hidden;
    	    height: 49.7%;
    }

    span {
	     
	     height: 100%;
	    }

	    .one {
	    	border-width: 5px;
	    	margin-left: 50px;

	    	@include breakpoint(medium down) {
	    		display: none;
	    	}
	    }

	    .two {
	    	border-width: 10px;
	    	margin-left: 50px;

	    	@include breakpoint(medium down) {
	    		display: none;
	    	}
	    }

	    .three {
	    	border-width: 30px;
	    	margin-left: 70px;

	    	@include breakpoint(medium down) {
	    		display: none;
	    	}
	    }

	    .four {
	    	border-width: 5px;
	    	margin-left: 70px;
	    }

	    .five {
	    	border-width: 9px;
	    	margin-left: 30px;
	    }

	    .six {
	    	border-width: 9px;
	    	margin-left: 20px;
	    }

	    .seven {
	    	border-width: 40px;
	    	margin-left: 20px;
	    }

	    .eight {
	    	    border-width: 150px;
	    	margin-left: 30px;

	    	 @include breakpoint(medium down) {
	    	    border-width: 78px;
			    margin-left: 4px;
			    margin-right: 0px;
			   }
	    }
}

}

.image-container {
		width: 63%;
	    display: inline-block;
	   height: 535px;
	    float: left;
	    border-left: 2em solid white;
	    border-right: 2em solid white;
	    position: relative;
	    overflow: hidden;

	    @include breakpoint(medium down) {
		 	border-left: 0px;
		 	height: 409px;
		 	width: 49%;
		 }

		 @include breakpoint(small only) {
		 	height: 250px;
		    width: 100%;
		    border: 0px !important;
		    border-radius: 20px 20px 0px 0px;

		    .device {
		    	margin-top: -40px;
    			position: relative;

    			.two {
				    border-bottom: 2px solid white;
				    margin-bottom: 12px;
				}

				.three {
				    border-bottom: 6px solid white;
				    margin-bottom: 7px;
				}

				.four {
				    border-bottom: 10px solid white;
				    margin-bottom: 4px;
				}
		    }
		 }   
	}
}






	

