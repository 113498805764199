.register-your-interest {

	padding: 0em 0em 2em;

	@include breakpoint(medium down) {

		padding: 0em;

		form {
			padding: 1em;


			@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
		    	padding: 2em 0em;
		    }

			@include breakpoint(small only) {
				display: none;
				padding: 2em 1.5em 0em;
			}
		}
	}


	@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	    padding:0em 1em;
	    }

	@include breakpoint(small only) {
		padding: 3em 0em 3em;
		background-image: url(../../assets/images/register-interest-mobile-background.png);
		background-size: 100%;
		background-position: top center;
		background-repeat: no-repeat;
	}

	.mobile-register {
		@include breakpoint(small only) {
			
			h2 {
				font-size: 60px;
			    text-align: center;
			    text-transform: uppercase;
			    line-height: 50px;
			    margin-bottom: 40px;

				b {
					display: block;
				}
			}

			.button {
				font-size: 21px;
			    color: white;
			    background-color: black;
			    width: 75%;
			    margin: auto;
			    display: block;
			}

			padding: 1em;
		}
	}

	.heading {
		margin-bottom: 1em;

		@include breakpoint(medium down) {
			margin-bottom: 0em;
		}
	}


	.form-container {
		width: 85%;
		display: inline-block;
		float: left;

		@include breakpoint(medium down) {
			width: 70%;
		}

		@include breakpoint(small only) {
			width: 100%;
		}

		[type='text'], [type='email'] {
			width: 49%;
		    display: inline-block;
		    height: 54px;
		    border: 2px solid black;
		    margin-right: 0.5%;
		    padding-left: 18px;

		    @include breakpoint(medium down) {
		    	width: 48%;
		    }

		    @include breakpoint(small only) {
				width: 100%;
			}

		}
	}

	.button {
		margin:0px;
		width: 15%;
    	padding: 16px 5px;

    	@include breakpoint(medium down) {
		    	width: 30%;
		    }

		    @include breakpoint(small only) {
			width: 100%;
		}

	}

	[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {

		font-family: 'Tungsten Medium';
    font-weight: normal;
    font-size: 21px;
	}

	
}

.footer {
	background-color: black;
    padding: 0em;

    	.device {
		margin-top: 1em;
		margin-bottom: 2em;
		background-color: white;

		@include breakpoint(medium down) {
			margin-bottom: 1em;
			margin-top: 3em;
		}

		@include breakpoint(small only) {
			margin-top: 0em;
			margin-bottom: 2em;
		}
	}

	.logo-text {
		img {
			width: 130px;
			height: 56px;

			@include breakpoint(medium down) {
				margin-bottom: 10px;
			}
		}
	}

	.grid-container {

		@include breakpoint(medium down) {
			padding: 0em 1em;
		}

		@include breakpoint(small only) {
			padding: 0em 0.7em;
		}
	}

	.menu-header-menu-container {
		@include breakpoint(medium down) {
		  	margin-top: 0px;
		  }
	}

	.footer-nav-container {
		@include breakpoint(medium down) {
			margin: 0px;
		}
	}

	.footer-address {
		@include breakpoint(medium down) {
		  	display: block;
		  	margin-top: 10px;
		  }

		  @include breakpoint(small only) {
		  	font-size: 12px;
		  	margin-top: 10px;
		  }
	}

	
	ul {
		text-align: right;
    float: right;
    margin-top: 10px;

    @include breakpoint(medium down) {
    	margin: 0.5em 0em 0em;
    	float: left;
    }

    li {
    	font-size: 16px;

    	 @include breakpoint(small only) {
    	 	display: block;
    	 	width: 100%;
    	 	text-align: left;
    	 }
    }

     
	 .is-active > a {
		color: white;
		background-color: black;
		font-weight: bold;

		&:after {
			content: '';
		    border-bottom: 2px solid white;
		    width: 100%;
		    position: relative;
		    height: 10px;
		    display: block;
		}
	}


     @include breakpoint(small only) {
	    .is-active > a {
		    background: #000000;
		    color: #ffffff;
		    margin-right: 10px;
		    margin-left: 10px;
		    font-weight: bold;
		    margin-left: 0px;
		    border-left: 5px solid white;
		    margin-left: 12px;
		    padding-left: 8px;
		    padding-top: 5px;
		    padding-bottom: 5px;

		    &:after {
		    	display:none;
		    }
		}
	}

    a {
    	color: white;

    	@include breakpoint(medium down) {
    		    padding: 0.7rem 0.7rem;
    	}

    }

	}

	.sub-footer {
		color: #b7b7b7;
	    padding-bottom: 0.5em;
	    padding-top: 1.5em;
	    border-top: 1px solid white;
	    margin-top: 2em;

	     a {
	     	color: #b0b0b0;

	     	&:hover {
	     		color:white;
	     		font-weight: bold;
	     	}
	     }

	     @include breakpoint(medium down) {
	     	.text-right {
	     		margin:1em 1em 0em;
	     		text-align: left;
	     	}
	     }

	     @include breakpoint(small only) {
	     	.text-right {
	     		margin:1em 0.5em 0em;
	     	}

	     	padding: 2em 1em 1em;
	     }

	    .fa {
	    	color: white;
	    	margin-right: 5px;
	    	font-size: 20px;
	    }

	}

	.mammoth-container {
		 @include breakpoint(small only) {
			display: block;
	    	margin-top: 9px;
	    }
	}


	.mammoth-copyright {
		max-width: 66px;
	    margin-left: 5px;
	    margin-top: -3px;
	    opacity: 0.5;

	    @include breakpoint(small only) {
	    	max-width: 57px;
	    }

	    &:hover {
	    	opacity:1;
	    }
	}
}