.thankyou, login {

  min-height: 70vh;

  h1 {
    font-size: 48px;
    line-height: 58px;
  }

  p {
    font-size: 1rem;
  }

}