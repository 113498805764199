.home-page {
	
	padding-left: 0em;
	padding-right: 0em;

	 

	.section-1 {

		padding-right: 3em;

		 /*@include breakpoint(landscape) {
		 	padding-right: 0em;
			padding: 0em 1em;
		 }*/

		@include breakpoint(medium down) {
			padding-right: 0em;
			padding: 0em 1em 2em;
		}

		 @include breakpoint(small only) {
		 	padding: 0px;
		 	margin:0px;
		 	width: 100%;
		 }
		
		.text-container {
			padding: 1em;

			@include breakpoint(small only) {
				padding: 0.5em 1.5em;
			}
		}


		.brand-text {
			display: inline-block;
		    float: left;
		    margin: 0em 3em 0em 0em;

		    width: 200px;
    		max-width: 100%;


		    @include breakpoint(medium down) {
		    	margin: 0em 3em 0em 0em;
		    }

		    @include breakpoint(small only) {
		    	width: 50%;
			    display: block;
			    float: none;
			    margin-bottom: 1.5em;
		    }

		    
		}
	}


	.section-2 {
		padding-left: 2em;

		/* @include breakpoint(landscape) {
		 	padding: 0em 1em 1em 0em;
		 }*/

		@include breakpoint(medium down) {
			padding: 2em 1em 1em;
		}

		@include breakpoint(small only) {
			padding: 1em 0em 1em;
		    margin: 0px;
		    width: 100%;
		}

		.image-container {
			@include breakpoint(small only) {
				margin-bottom: 0em;
			}
		}

		.text-container {
			padding: 1em;
			padding-top: 1em;

			@include breakpoint(small only) {
				background-image: url(../../assets/images/Home/home-mobile-background-2.png);
				background-size: contain;
				background-repeat: no-repeat;
				height: 100%;
				background-repeat: repeat; 

			    img {
			    	margin-left: -10px;
    				margin-bottom: 2em;

    				@include breakpoint(small only) {
    					width: 83%;
    					margin-bottom: 1.5em;
    				}
				    
			    }

			    .content {
			    	padding: 1.5em;
			    width: 92%;
			    float: right;
			    padding-right: 3em;
			    }
			}
		}
	}

	

	.section-3 {
		padding: 5em 2em; 

		/*@include breakpoint(landscape) {
			padding: 2em;
		}*/

		@include breakpoint(medium down) {
			padding: 1em 0em;
		}

		@include breakpoint(small only) {
		     padding: 1em 0em;
		}
	}

	.section-4 {
		padding-left: 2em;

		/* @include breakpoint(landscape) {
		 	padding: 0em 1em 1em 0em;
		 }*/

		@include breakpoint(medium down) {
			padding: 2em 1em 1em;
		}

		@include breakpoint(small only) {
			padding: 1em 0em 1em;
		    margin: 0px;
		    width: 100%;
		}

		.image-container {
			@include breakpoint(small only) {
				margin-bottom: 0em;
			}
		}

		.text-container {
			padding: 1em;
			padding-top: 1em;

			@include breakpoint(small only) {

			    padding: 1.5em;
			    padding-bottom: 0em;

			    img {
			    	margin-left: -10px;
    				margin-bottom: 2em;
			    }

			    .content {
			    	padding: 1.5em;
			    width: 72%;
			    float: right;
			    padding-right: 3em;
			    }
			}
		}
	}

	.image-container {
			margin:2em 0em;

			@include breakpoint(medium down) {
				margin:1em 0em;
			}
		}


		.social-wall-section {

			@include breakpoint(medium down) {
				padding: 0em 0em 2em;
			}


		@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
		   padding:0em 1em 2em;
		    }

			@include breakpoint(small only) {
				padding: 0em;

				.cell {
					width: 100%;
				    margin-left: 0px;
				    margin-right: 0px;
				}
			}

			.juicer-container {
				
				padding: 1.5em;


				@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
				    padding: 1.5em 0em;
				    }

				.juicer-feed.modern .j-poster h3, .juicer-feed.modern .j-poster .j-date {
					display: none;
				}

				.juicer-feed.modern li.feed-item {
				    padding: 0;
				    border-radius: 5px;
				    border: 1px solid #dfdfdf;
				}

				.juicer-feed .j-meta {
					display: none;
				}

				p {
					color: black;
				}

				.juicer-feed.modern .j-text {
					padding: 20px 20px 10px;
				}

				.juicer-button {
					padding: 16px 30px;
				    text-transform: uppercase;
				    font-weight: bold;
				    background-color: white;
				    color: black;
				    border: 2px solid black;
				    margin: 1em auto;
				    font-family: 'Tungsten Medium';
				    font-weight: normal;
				    font-size: 18px;
				    letter-spacing: 2px;

				    &:hover {
				    	background-color: black;
				    	color: white !important;
				    }
				}

				.j-loading-wrapper {
					display: none !important;
				}
			
			}
		}
}